.nav-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);

  @media (min-width: 600px) {
    min-height: calc(100vh - 120px);
  }

  @media (min-width: 600px) {
    min-height: calc(100vh - 104px);
  }

  @media (min-width: 1280px) {
    min-height: calc(100vh - 132px);
  }
}

.fadeIn {
  animation: 0.2s fadeIn forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
